import { Sportevent } from "../models/Sportevent";
import { v4 as uuidv4} from "uuid";
import beginRegistrationDate from "./beginRegistrationDate"; // Jänner = 0

const agenda: Sportevent[] = [
  {
    Id: uuidv4(),
    Identifyer: '26PJE',
    RegistrationStartDate: undefined, // Jänner = 0
    RegistrationEndDate: undefined, // Jänner = 0
    Title: "Snow Kidz Academy",
    Place: undefined,
    PlaceLink: undefined,
    Text: "Die Termine für unsere Snow Kidz Academy und unsere Renntrainingstage werden im Jänner bekanntgegeben. Auch eine Vereinsmeisterschaft wird wieder durchgeführt.",
    Info: undefined,
    Category: ["Kinder", "Jugendliche"],
    Begin: new Date(2025, 1, 1, 0, 0), // Jänner = 0
    End: undefined, // Jänner = 0
    IsWholeMonth: true,
    IsWholeDay: true,
    IsCancelled: false,
    CancelledText: "",
    PriceEvent: undefined,
    PriceLocation: undefined,
    SkillType: 'kurs'
  },
  // {
  //   Id: uuidv4(),
  //   Identifyer: 'WKJ8D',
  //   RegistrationStartDate: new Date(2024, 0, 1, 0, 0), // Jänner = 0
  //   RegistrationEndDate: new Date(2024, 0, 1, 0, 0), // Jänner = 0
  //   Title: "World Snow Day",
  //   Place: "Arena am Präbichl",
  //   PlaceLink: "https://www.praebichl.at/winter/",
  //   Text: "Auch dieses Jahr veranstalten wir wieder den weltweit durchgeführten World Snow Day.",
  //   Info: undefined,
  //   Category: ["Kinder", "Jugendliche"],
  //   Begin: new Date(2024, 0, 21, 0, 0), // Jänner = 0
  //   End: undefined, // Jänner = 0
  //   IsWholeMonth: false,
  //   IsWholeDay: true,
  //   IsCancelled: false,
  //   CancelledText: "",
  //   PriceEvent: undefined,
  //   PriceLocation: undefined,
  //   SkillType: 'kurs'
  // },
  {
    Id: uuidv4(),
    Identifyer: 'V7BJM',
    RegistrationStartDate: beginRegistrationDate, // Jänner = 0 // Jänner = 0
    RegistrationEndDate: new Date(2024, 11, 27, 23, 59), // Jänner = 0
    Title: "Kinder und Jugend - Skikurs",
    Place: "Arena am Präbichl",
    PlaceLink: "https://www.praebichl.at/winter/",
    Text: "Unser jährlicher Kurs für alle Kinder und Jugendlichen in den Weihnachtsferien.",
    Info: undefined,
    Category: ["Kinder", "Jugend"],
    Begin: new Date(2025, 0, 3, 10, 0), // Jänner = 0
    End: new Date(2025, 0, 6, 13, 0), // Jänner = 0
    IsWholeMonth: false,
    IsWholeDay: false,
    IsCancelled: false,
    CancelledText: "",
    PriceEvent: 98.0,
    PriceLocation: 65,
    SkillType: 'kurs'
  },
  {
    Id: uuidv4(),
    Identifyer: '1XPSZ',
    RegistrationStartDate: beginRegistrationDate, // Jänner = 0 // Jänner = 0
    RegistrationEndDate: new Date(2024, 11, 27, 23, 59), // Jänner = 0
    Title: "Skikindergarten",
    Place: "Arena am Präbichl",
    PlaceLink: "https://www.praebichl.at/winter/",
    Text: "Für unsere Kleinsten ab 4 Jahren, gibt’s den Skikindergarten.",
    Info: undefined,
    Category: ["Kinder", "Jugend"],
    Begin: new Date(2025, 0, 3, 10, 0), // Jänner = 0
    End: new Date(2025, 0, 6, 13, 0), // Jänner = 0
    IsWholeMonth: false,
    IsWholeDay: false,
    IsCancelled: false,
    CancelledText: "",
    PriceEvent: 98.0,
    PriceLocation: 65,
    SkillType: 'kurs'
  },
  {
    Id: uuidv4(),
    Identifyer: 'KQE7B',
    RegistrationStartDate: beginRegistrationDate, // Jänner = 0 // Jänner = 0
    RegistrationEndDate: new Date(2024, 11, 27, 23, 59), // Jänner = 0
    Title: "Jugend Snowboardkurs",
    Place: "Arena am Präbichl",
    PlaceLink: "https://www.praebichl.at/winter/",
    Text: "Speziell für unsere Jugend ab 8 Jahren bieten wir unseren Snowboardkurs an.",
    Info: undefined,
    Category: ["Snowboard", "Jugend"],
    Begin: new Date(2025, 0, 4, 10, 0), // Jänner = 0
    End: new Date(2025, 0, 6, 13, 0), // Jänner = 0
    IsWholeMonth: false,
    IsWholeDay: false,
    IsCancelled: false,
    CancelledText: "",
    PriceEvent: 98.0,
    PriceLocation: 65,
    SkillType: 'kurs'
  },
  {
    Id: uuidv4(),
    Identifyer: '9XCKZ',
    RegistrationStartDate: undefined, // Jänner = 0
    RegistrationEndDate: undefined, // Jänner = 0
    Title: "Skiguiding",
    Place: "Arena am Präbichl",
    PlaceLink: "https://www.praebichl.at/winter/",
    Text: "Für fortgeschrittene Erwachsene gibt es auch heuer wieder das Skiguiding unter Leitung unserer topausgebildeten Instruktoren.",
    Info: undefined,
    Category: ["Skiguiding", "Erwachsene", "Fortgeschrittene"],
    Begin: new Date(2025, 0, 4, 10, 15), // Jänner = 0
    End: new Date(2025, 0, 6, 12, 45), // Jänner = 0
    IsWholeMonth: false,
    IsWholeDay: false,
    IsCancelled: false,
    CancelledText: "",
    PriceEvent: undefined,
    PriceLocation: undefined,
    SkillType: 'kurs'
  },
  {
    Id: uuidv4(),
    Identifyer: 'EE9ZS',
    RegistrationStartDate: new Date(2025, 0, 7, 0, 0), // Jänner = 0
    RegistrationEndDate: new Date(2025, 1, 3, 23, 59), // Jänner = 0
    Title: "Langlaufkurs freie Technik",
    Place: "Krumpenloipe in Trofaiach",
    PlaceLink: "https://www.krumpenloipe.at/",
    Text: "Langlaufkurs für Anfänger und Fortgeschrittene in der freien Technik (Skating).",
    Info: "Die Biathlontrainings, mit unserer neuen hochmodernen Laseranlage, finden an den Tagen der Langlaufkurse auf der Krumpenloipe statt. Kurskosten ohne Loipenkarte, diese ist vor Ort zu besorgen.",
    Category: ["Langlaufen", "Erwachsene", "Fortgeschrittene"],
    Begin: new Date(2025, 1, 8, 12, 0), // Jänner = 0
    End: new Date(2025, 1, 9, 14, 30), // Jänner = 0
    IsWholeMonth: false,
    IsWholeDay: false,
    IsCancelled: false,
    CancelledText: "",
    PriceEvent: 35,
    PriceLocation: undefined,
    SkillType: 'langlaufen'
  },
  {
    Id: uuidv4(),
    Identifyer: 'Q34DC',
    RegistrationStartDate: new Date(2025, 0, 7, 0, 0), // Jänner = 0
    RegistrationEndDate: new Date(2025, 1, 3, 23, 59), // Jänner = 0
    Title: "Langlaufkurs - klassisch",
    Place: "Krumpenloipe in Trofaiach",
    PlaceLink: "https://www.krumpenloipe.at/",
    Text: "Langlaufkurs für Anfänger und Fortgeschrittene in der klassischen Technik.",
    Info: "Die Biathlontrainings, mit unserer neuen hochmodernen Laseranlage, finden an den Tagen der Langlaufkurse auf der Krumpenloipe statt. Kurskosten ohne Loipenkarte, diese ist vor Ort zu besorgen.",
    Category: ["Langlaufen", "Erwachsene", "Anfänger", "Fortgeschrittene"],
    Begin: new Date(2025, 1, 8, 9, 0), // Jänner = 0
    End: new Date(2025, 1, 9, 11, 30), // Jänner = 0
    IsWholeMonth: false,
    IsWholeDay: false,
    IsCancelled: false,
    CancelledText: "",
    PriceEvent: 35,
    PriceLocation: undefined,
    SkillType: 'langlaufen'
  },
  {
    Id: uuidv4(),
    Identifyer: 'NH03Q',
    RegistrationStartDate: undefined, // Jänner = 0
    RegistrationEndDate: undefined, // Jänner = 0
    Title: "Freeride und Touren Basiswissen",
    Place: "Gasthof Langreiter am Präbichl",
    PlaceLink: "http://www.gasthaus-langreiter.at/",
    Text: "Der Kurs richtet sich an Anfänger, wenig erfahrene Freerider und Tourengeher.",
    Info: "For alle Interessierten gibt's alle Touren und Freeridekurse als komplettes Set.",
    Category: ["Langlaufen", "Erwachsene", "Anfänger", "Fortgeschrittene"],
    Begin: new Date(2025, 0, 5, 14, 30), // Jänner = 0
    End: new Date(2025, 0, 5, 17, 0), // Jänner = 0
    IsWholeMonth: false,
    IsWholeDay: false,
    IsCancelled: false,
    CancelledText: "",
    PriceEvent: undefined,
    PriceLocation: undefined,
    SkillType: 'kurs'
  },
  {
    Id: uuidv4(),
    Identifyer: 'AAX4Y',
    RegistrationStartDate: undefined, // Jänner = 0
    RegistrationEndDate: undefined, // Jänner = 0
    Title: "Freeride und Touren Planung und Risikomanagement",
    Text: "Unser Aufbaukurs für Freerider und Tourengeher.",
    Info: "For alle Interessierten gibt's alle Touren und Freeridekurse als komplettes Set.",
    Place: "Gasthof Langreiter am Präbichl",
    PlaceLink: "http://www.gasthaus-langreiter.at/",
    Category: ["Freeriding", "Erwachsene", "Skitouren", "Anfänger", "Fortgeschrittene"],
    Begin: new Date(2025, 0, 6, 14, 30), // Jänner = 0
    End: new Date(2025, 0, 6, 17, 0), // Jänner = 0
    IsWholeMonth: false,
    IsWholeDay: false,
    IsCancelled: false,
    CancelledText: "",
    PriceEvent: undefined,
    PriceLocation: undefined,
    SkillType: 'kurs'
  },
  {
    Id: uuidv4(),
    Identifyer: 'OO4KB',
    RegistrationStartDate: undefined, // Jänner = 0
    RegistrationEndDate: undefined, // Jänner = 0
    Title: "Freeride Praxis",
    Text: "Für alle Erwachsenen, die sich beim Fahren abseits der Pisten weiter verbessern wollen.",
    Info: "Für alle Interessierten gibt's alle Touren und Freeridekurse als komplettes Set.",
    Place: "Arena am Präbichl",
    PlaceLink: "https://www.praebichl.at/winter/",
    Category: ["Freeriding", "Erwachsene", "Anfänger", "Fortgeschrittene"],
    Begin: new Date(2025, 0, 4, 10, 15), // Jänner = 0
    End: new Date(2025, 0, 6, 12, 45), // Jänner = 0
    IsWholeMonth: false,
    IsWholeDay: false,
    IsCancelled: false,
    CancelledText: "",
    PriceEvent: undefined,
    PriceLocation: undefined,
    SkillType: 'kurs'
  },
  {
    Id: uuidv4(),
    Identifyer: 'V4NSC',
    RegistrationStartDate: undefined, // Jänner = 0
    RegistrationEndDate: undefined, // Jänner = 0
    Title: "Freeride Camp",
    Place: "Fieberbrunn",
    PlaceLink: "https://www.fieberbrunn.com/de/winter/freeride",
    Text: "Für alle Erwachsenen, die sich beim Freeriden vergnügen wollen, planen wir diese Saison ein Camp im österreichischen Freeridehotspot.",
    Info: "Für alle Interessierten gibt's alle Touren und Freeridekurse als komplettes Set.",
    Category: ["Camp", "Erwachsene", "Anfänger", "Fortgeschrittene"],
    Begin: new Date(2025, 2, 1), // Jänner = 0
    End: undefined, // Jänner = 0
    IsWholeMonth: true,
    IsWholeDay: false,
    IsCancelled: false,
    CancelledText: "",
    PriceEvent: undefined,
    PriceLocation: undefined,
    SkillType: 'kurs'
  },
  {
    Id: uuidv4(),
    Identifyer: 'JR37Q',
    RegistrationStartDate: new Date(2025, 0, 7, 0, 0), // Jänner = 0
    RegistrationEndDate: new Date(2025, 1, 3, 23, 59), // Jänner = 0
    Title: "Skitouren Praxis",
    Place: "Arena am Präbichl",
    PlaceLink: "https://www.praebichl.at/winter/",
    Text: "Diese geführte Skitour richtet sich an alle Wintersportler, welche erst ins Tourengehen einsteigen möchten und noch über wenig Erfahrung verfügen.",
    Info: "Für alle Interessierten gibt's alle Touren und Freeridekurse als komplettes Set.",
    Category: ["Freeriding", "Anfänger", "Fortgeschrittene"],
    Begin: new Date(2025, 1, 1, 0, 0), // Jänner = 0
    End: undefined, // Jänner = 0
    IsWholeMonth: true,
    IsWholeDay: false,
    IsCancelled: false,
    CancelledText: "",
    PriceEvent: undefined,
    PriceLocation: undefined,
    SkillType: 'kurs'
  },
  {
    Id: uuidv4(),
    Identifyer: 'QUH0H',
    RegistrationStartDate: undefined, // Jänner = 0
    RegistrationEndDate: undefined, // Jänner = 0
    Title: "LVS Check und Übung",
    Place: "Gasthof Langreiter am Präbichl",
    PlaceLink: "http://www.gasthaus-langreiter.at/",
    Text: "Für alle Vereinsmitglieder bieten wir die Möglichkeit, kostenlos die eigenen LVS Geräte zu überprüfen und mit diesen auch zu trainieren.",
    Info: undefined,
    Category: ["Kinder", "Jugendliche"],
    Begin: new Date(2025, 0, 5, 15, 0), // Jänner = 0
    End: undefined, // Jänner = 0
    IsWholeMonth: false,
    IsWholeDay: false,
    IsCancelled: false,
    CancelledText: "",
    PriceEvent: undefined,
    PriceLocation: undefined,
    SkillType: 'kurs'
  },
  // {
  //   Id: uuidv4(),
  //   Identifyer: '',
  //   RegistrationActive: false,
  //   RegistrationFinished: false,
  //   Title: "xxx",
  //   Place: "xxx",
  //   PlaceLink: "",
  //   Text: "xxx",
  //   Info: "xxx",
  //   "dateBegin": "0", // Jänner = 0
  //   "dateEnd": "0",
  //   "dateYear": "2022",
  //   "dateMonth": "Jänner",
  //   "timeBegin": "10", // Jänner = 0
  //   "timeEnd": "13",
  // },
];

export default agenda;