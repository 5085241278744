import { isAfter, isBefore } from "date-fns"


export type Sportevent = {
  Id: string
  
  RegistrationStartDate: Date | undefined
  RegistrationEndDate: Date | undefined

  Identifyer: string
  Title: string
  Text: string
  Info: string | undefined
  Category: string[]
  Place: string | undefined
  PlaceLink: string | undefined
  Begin: Date
  IsWholeMonth: boolean
  IsWholeDay: boolean
  End: Date | undefined

  IsCancelled: boolean | undefined
  CancelledText: string | undefined
  
  PriceEvent?: number | undefined
  PriceLocation?: number | undefined
  SkillType: 'kurs' | 'langlaufen'
}

export function getEventState (event: Sportevent) : 'pending' | 'registration' | 'canceled' | 'registration-finished' {
  const isAfterStartRegistrationDate = event.RegistrationStartDate != undefined && isAfter(new Date(), event.RegistrationStartDate)
  const isBeforeEndRegistrationDate = event.RegistrationEndDate != undefined && isBefore(new Date(), event.RegistrationEndDate)

  if(event.IsCancelled){
    return 'canceled'
  }

  if(!isAfterStartRegistrationDate) {
    return 'pending'
  }

  if(isAfterStartRegistrationDate && isBeforeEndRegistrationDate){
    return 'registration'
  }

  if(!isBeforeEndRegistrationDate){
    return 'registration-finished'
  }

  return 'pending'
}