import { Link } from "react-router-dom";
import {CopyToClipboard} from 'react-copy-to-clipboard';

type Props = {
  email: string,
  error: string,
  message: string
}

const SendError = (props: Props) => 
  <>
    <section className='content-section'>
      <h3 style={{ color: 'red' }}>
        Anmeldung konnte nicht versendet werden!
      </h3>
      <p>
        Es gab einen Fehler beim E-Mail Versand. <br />
        Bitte versuche es erneut oder sende die Informationen in der grauen box direkt an <b><a href="mailto:wintersport@dsv-leoben.org">wintersport@dsv-leoben.org</a></b>.
      </p>
      <p>
        <Link to="/">Zurück zur Startseite</Link>
      </p>
    </section>
    <section className='content-section'>
      <h3>Fehler</h3>
      <code style={{ color: 'red', whiteSpace: 'pre-line' }}>{props.error}</code>
    </section>
    <section style={{ backgroundColor: 'var(--color-dark-gray)'}} className='content-section' dangerouslySetInnerHTML={{ __html: props.message }} />
  </>

export default SendError;