import { Link } from "react-router-dom";

type Props = {
  email: string,
  message: string
}

const SendSuccess = (props: Props) => 
  <>
    <section className='content-section'>
      <h3>
        Anmeldung erfolgreich versendet!
      </h3>
      <p>
        Die vorläufige Anmeldung wurde erfolgreich an <b><a href="mailto:wintersport@dsv-leoben.org">wintersport@dsv-leoben.org</a></b> <br />
        sowie <b>{props.email}</b> versendet.
      </p>
      <p>
        <b>Die Anmeldung erlangt erst Gültigkeit mit der Anmeldebestätigung und der Vereinsanmeldung, welche wir nach Überweisung der Kurskosten inkl. dem Mitgliedsbeitrag auf unser Konto zusenden.</b>
      </p>
      <p>
        Sollte die E-Mail nicht innerhalb der nächsten fünf Minuten angekommen sein bitte auch im <b>Spam-Ordner</b> nachsehen.
      </p>
      <p>
        <Link to="/">Zurück zur Startseite</Link>
      </p>
    </section>
    <section className='content-section' dangerouslySetInnerHTML={{ __html: props.message }} />
  </>

export default SendSuccess;