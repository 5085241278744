import React from 'react';
import './App.css';
import logo from './dsv-logo.svg';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import EventsPage from './pages/EventsPage';
import RegistrationPage from './pages/RegistrationPage';

import Mitgliedschaft from "./components/footer-partials/Mitgliedschaft";
import Kontakt from "./components/footer-partials/Kontakt";
import AktuelleInformationen from "./components/footer-partials/AktuelleInformationen";
import Haftungsausschluss from "./components/footer-partials/Haftungsausschluss";

function App() {
  return (
    <div className="App">
      <header>
        {/* <svg className='swushTop' viewBox="0 0 385 115">
          <path d="M0,0l385,0v115C292.9,37.2,170.1,10.9,0,0" />
        </svg> */}
        <div className="logo-wrapper">
          <img src={logo} alt=""></img>
        </div>
      </header>

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<EventsPage />} />
          <Route path="/anmeldung/:eventid" element={<RegistrationPage />} />
          {/* <Route path="/anmeldung/erfolgreich/:email" element={<SendSuccessPage />} /> */}
        </Routes>
      </BrowserRouter>
      <footer>
        <Mitgliedschaft />
        <Kontakt />
        <AktuelleInformationen />
        <Haftungsausschluss />
      </footer>
      {/* <footer style={{ backgroundImage: `url('swushBottom.svg')` }}></footer> */}
    </div>
  );
}

export default App;
